<template>
  <div class="container">
    <Spinner
      v-show="this.$store.state.spinner_waitData"
      class="style-spinner"
    />
    <BaseScore class="content" @survey-click="getScroll" isEx="true" />
  </div>

  <!-- <div class="wrapper">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-xl-6 col-12 col-logo">
            <div class="logo-ttb-blue">
              <img src="../assets/surveyWeb/t-logo-skyblue-2.svg" alt="" />
            </div>
            <img src="../assets/logo-ttb.svg" alt="logo-ttb" class="logo-ttb" />
            <span
              >ให้คะแนน <br />
              ความพึงพอใจ</span
            >
          </div>
          <div class="col-xl-6 col-12">
            <div
              class="warpper-content-res"
              :class="{ style_scrollBar: statusScrollBar }"
            >
              <div class="content-res">
                <img src="../assets/logo-ttb.svg" alt="logo-ttb" />
                <p class="mt-4">ให้คะแนนความพึงพอใจ</p>
              </div>
              <BaseScore
                class="content"
                @survey-click="getScroll"
                isEx="true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</template>

<script>
import Spinner from '../views/Spinner.vue'
// import axios from 'axios'
// import config from '../config'
export default {
  components: { Spinner },
  // updated() {
  //   console.log('Page SurveyWeb')
  //   console.log(this.$store.state.survey.score)
  // },
  data() {
    return {
      submitsurveyUrl: '/webchat/submitsurvey',
      score: 0,
      star: 0,
      note: '',
      sessionid: '',
      spinner_waitData: false,
      statusScrollBar: false,
    }
  },
  methods: {
    // summitSurvey() {
    //   let data = {
    //     osat_score: this.$store.state.survey.score,
    //     nps_score: this.$store.state.survey.star,
    //     verbatim: this.$store.state.survey.note,
    //     session_id: this.$store.state.sessionid,
    //   }
    //   console.log(data)
    //   this.spinner_waitData = true
    //   axios
    //     .post(`${config.baseUrl + config.submitSurveyEndpoint}`, data)
    //     .then((res) => {
    //       if (res.status == 200) {
    //         console.log('res')
    //         console.log(res)
    //         this.spinner_waitData = false
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error)
    //       this.$router.push('error-page')
    //     })
    // },
    getScroll() {
      if (this.$store.state.survey.star > 0) {
        this.statusScrollBar = true
      }
    },
  },
  mounted() {},
}
</script>

<style scoped>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.container {
  display: flex;
  justify-content: center;
}
.wrapper {
  min-height: 100vh;
  background-color: #012d63;
  margin: auto;
  position: relative;
  align-items: center;
  display: flex;
}
.card {
  border-radius: 0;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  margin-top: 0 !important;
  height: 43.125rem;
}
.col-logo {
  display: none;
}
.col-logo span {
  color: #002d63;
  position: absolute;
  font-size: 48px;
  font-weight: 700;
  line-height: 70.08px;
  left: 20.875rem;
  top: 8.5rem;
  text-align: left;
}
.content {
  width: 36rem !important;
}
.logo-ttb {
  position: absolute;
  top: 4.5rem;
  left: 20.875rem;
}
.logo-ttb-blue {
  width: 34.6875rem;
  height: auto;
  left: -1px;
  top: -1px;
  position: absolute;
}
.style-spinner {
  position: fixed;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.7);
  max-height: 100%;
  width: 100%;
}

@media screen and (max-width: 576px) {
  .content {
    width: 20rem !important;
  }
}
@media screen and (max-width: 720px) {
  .wrapper {
    justify-content: center;
  }
  .card {
    border-radius: 24px;
    width: 21.875rem !important;
    height: auto;
  }
  .content-res {
    margin-top: 1.5rem;
  }
  .content-res p {
    font-size: 18px;
    font-weight: 700;
  }
  .content {
    width: 100%;
  }
  .wrapper-btn-submit {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 722px) {
  * {
    padding: 0 !important;
  }
  .wrapper {
    justify-content: center;
  }
  .card {
    border-radius: 24px;
    width: 36rem;
    height: auto;
  }
  .content-res p {
    font-size: 18px;
    font-weight: 700;
  }
  .warpper-content-res {
    padding: 1rem 1rem 0rem !important;
  }
  .content {
    width: 100%;
  }
}

@media screen and (max-width: 1196.98px) {
  .wrapper {
    justify-content: center;
  }
  .card {
    border-radius: 24px;
    width: 36rem;
  }
  .content {
    width: 100%;
  }
}

@media screen and (min-width: 1200px) {
  /* style firefox */
  * {
    scrollbar-width: 8px;
    scrollbar-color: #0050f0 #dfe6ec;
  }
  .col-logo {
    display: grid;
  }
  .card {
    width: 72.9375rem;
    height: 43rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .wrapper-btn-submit {
    display: flex;
    justify-self: center;
  }
  .btn-submit {
    margin-left: 6rem;
    margin-top: 0.5rem;
  }
  .content-res {
    display: none;
  }
  .wrapper {
    justify-content: start;
  }
  .warpper-content-res {
    max-width: 35.5rem;
    padding-top: 0rem !important;
  }
  .style_scrollBar {
    overflow: hidden;
    overflow-y: scroll;
    height: 41.125rem;
    margin-top: 1rem;
    padding-top: 0 !important;
  }

  /* Scrollbar Styling */
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: #dfe6ec;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    margin: 1rem 0;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 4px;
    border-radius: 4px;
    background: #0050f0;
  }
}
</style>
